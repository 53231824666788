import React, { useState } from "react";
import clsx from "clsx";

import { AccordionItem } from "@molecules";
import { Container } from "@atoms";

const Accordion = ({ blocks, showBorders, className: _className, id }) => {
  const [open, setOpen] = useState(0);

  return (
    <section className={clsx("my-12 sm:mb-20", _className)} id={id}>
      <Container variant="xs">
        {blocks?.map((block, i) => {
          return (
            <AccordionItem
              key={block.uid}
              {...block}
              showBorders={showBorders}
              itemClassName={showBorders && i === 0 ? "border-t-2" : undefined}
              open={open === i}
              setOpen={() => setOpen(i)}
            />
          );
        })}
      </Container>
    </section>
  );
};

Accordion.defaultProps = {};

export default Accordion;
